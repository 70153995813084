<template>
  <div class="container bgblue">
    <el-card class="form-card mt5">
      <div class="header">
        <el-row>
          <el-form :model="form" class="demo-form-inline" label-width="80px">
            <el-col :span="6">
              <el-form-item label="物料名称:">
                <el-input
                  v-model="form.productName"
                  placeholder="请输入物料名称:"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="物料类型:">
                <el-input
                  v-model="form.specs"
                  placeholder="请输入物料类型"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="库存数量:">
                <div class="count">
                  <el-input v-model="form.min" clearable></el-input>
                  <span style="margin: 0 20px">~</span>
                  <el-input v-model="form.max" clearable></el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label-width="20px">
                <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="onSubmit"
                  >查询</el-button
                >
                <el-button icon="el-icon-refresh" @click="onReset"
                  >重置</el-button
                >
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </div>
    </el-card>
    <el-card class="form-card mt5">
      <div class="table">
        <div class="btns">
          <!-- <div class="warningBtn" style="width: 200px">
          <img class="icon" src="../../assets/images/u70.png" alt="" />
          <div class="btn">
            <a
              href="https://house.zhongliangxny.com/resources/upload/file_202405295309284353207.xlsx"
              download
              >库存调整模板下载</a
            >
          </div>
        </div>
        <div
          class="warningBtn"
          style="width: 150px; margin-left: 20px"
          @click="showDialog = true"
        >
          <img class="icon" src="../../assets/images/u70.png" alt="" />
          <div class="btn"></div>
        </div> -->

          <el-button type="warning" icon="el-icon-tickets">
            <a
              href="https://house.zhongliangxny.com/resources/upload/file_202405295309284353207.xlsx"
              download
              >库存调整模板下载</a
            ></el-button
          >
          <el-button
            type="warning"
            @click="showDialog = true"
            icon="el-icon-tickets"
            >库存调整</el-button
          >
        </div>
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          height="calc(100vh - 250px)"
        >
          <!-- <el-table-column align="center" prop="" label="操作">
            <template v-slot="{ row }">
              <el-button
                type="text "
                class="btn-blue"
                plain
                @click="handelDetail(row)"
                >明细</el-button
              >
            </template>
          </el-table-column> -->

          <el-table-column align="center" prop="specs" label="物料类型">
          </el-table-column>
          <el-table-column align="center" prop="productName" label="物料名称">
          </el-table-column>
          <el-table-column align="center" prop="num" label="当前数量">
          </el-table-column>
          <el-table-column align="center" prop="unit" label="物料单位">
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="params.currPage"
            :page-size="params.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totol"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <!-- 弹框 -->
    <div class="dialog">
      <el-dialog title="文件上传" :visible.sync="showDialog" width="30%">
        <el-upload class="upload-demo" drag :action="action">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import baseURL from "../../utils/baseURL"
export default {
  data() {
    return {
      tableData: [],
      showDialog: false,
      action: "https://house.zhongliangxny.com/api/upload/uploadFile",
      form: {
        min: "",
        max: "",
        productName: "",
        specs: "",
      },
      params: {
        count: 0,
        currPage: 1,
        pageCount: 0,
        pageSize: 30,
        condition: {},
      },
      totol: 0,
    };
  },
  methods: {
    onSubmit() {
      this.params.currPage = 1;
      this.getData();
    },
    async getData() {
      this.params.condition.loginName = localStorage.getItem("loginName");
      const res = await this.$http.post("/houseWareProduct/listPlatform", this.params);
      if (res.data.code === 200) {
        this.totol = res.data.data.count;
        this.tableData = res.data.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    },
    onReset() {
      this.form = {};
      this.getData();
    },
    handelDetail(row) {
      this.$router.push(`/detailsInventory?specs=${row.specs}`);
    },
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.params.currPage = val;
      this.getData();
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 5px;
  box-sizing: border-box;

  ::v-deep .el-table td {
    padding: 0.5px 0;
    box-sizing: border-box;
  }
  ::v-deep .el-form-item__label {
    font-size: 13px;
  }
  .el-table {
    margin-top: 10px !important;
  }
  .header {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    padding: 10px;
    padding-bottom: 0px !important;
    box-sizing: border-box;
    ::v-deep .el-select {
      width: 100% !important;
    }

    .count {
      display: flex;
      ::v-deep .el-input__inner {
        width: 100%;
        height: 35px !important;
        line-height: 35px !important;
      }
    }

    ::v-deep .el-input__inner {
      width: 100%;
      height: 35px !important;
      line-height: 35px !important;
    }
  }
  .table {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    height: calc(100vh - 150px);

    ::v-deep .el-button--primary.is-plain {
      height: 20px !important;
      line-height: 6px !important;
    }

    // ::v-deep .el-table td {
    //   padding: 2px 0;
    // }
    .btns {
      display: flex;
    }
  }
  .page {
    width: 100%;
    text-align: end;
  }
  .dialog {
    width: 100%;
    text-align: center;

    ::v-deep .el-button {
      height: 35px !important;
    }

    .img {
      width: 150px;
      height: 150px;
      margin: 10px;
    }
  }
}

.warningBtn {
  display: flex;
  justify-content: space-around;
  align-items: center;

  height: 40px;
  line-height: 40px;
  background-color: #f59a23;
  color: #fff;
  border: 0;
  border-radius: 5px;
  padding: 0 15px;
  box-sizing: border-box;
  .icon {
    width: 20px;
    height: 20px;
  }
}

::v-deep .el-dialog__body {
  padding: 0 0 30px 0 !important;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #f59a23 !important;
}
::v-deep .el-card__body {
  padding: 0px !important;
}
::v-deep .el-card {
  border-radius: 10px;
}
</style>
